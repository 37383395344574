(function() {
    'use strict';

    document.addEventListener('DOMContentLoaded', function(){
        iniciarApp();
    });

    function iniciarApp() {
                    
        iniciarCarrusel();    

    }

    function iniciarCarrusel() {
        const textElement = document.querySelector('.text-carrusel');
        const textWidth = textElement.offsetWidth;
        const screenWidth = window.innerWidth;
        const duration = (textWidth + screenWidth) / 200;
  
        textElement.style.animationDuration = duration + 's';
      }
  

        
})();